<template>
  <div class="page">
    <template v-if="init">
      <van-nav-bar left-arrow
                   @click-left="onClickLeft">
        <img src="@/assets/images/prev_blank.png"
             slot="left"
             alt=""
             class="back-icon" />
        <span slot="title"
              class="title">收银台</span>
      </van-nav-bar>

      <div class="content">
        <p class="content-title">应付金额</p>
        <p><span style="font-size: 16px">¥</span> {{ amount }}</p>
      </div>

      <div class="card">
        <p class="card-title">支付方式</p>
        <div>
          <van-radio-group v-model="type">
            <div class="type-item" v-if="pay_setting['WX']">
              <div class="type-left"
                   @click="type = '1'">
                <img src="@/assets/images/wechat.png"
                     alt=""
                     class="type-icon" />
                微信支付
              </div>
              <van-radio name="1"
                         :icon-size="18"
                         checked-color="#00A0AC"></van-radio>
            </div>
            <div class="type-item" v-if="pay_setting['ZFB']">
              <div class="type-left"
                   @click="type = '2'">
                <img src="@/assets/images/zhifubao.png"
                     alt=""
                     class="type-icon" />
                支付宝支付
              </div>
              <van-radio name="2"
                         :icon-size="18"
                         checked-color="#00A0AC"></van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>

      <div class="footer">
        <div class="footer-button"
             @click="pay">确认支付</div>
      </div>
    </template>
  </div>
</template>

<script>
import { payUnified } from '@/api/public'
import { pay, paySetting } from '@/api/form.js'
// import VConsole from 'vconsole'

// eslint-disable-next-line no-new
// new VConsole()

export default {
  data () {
    return {
      type: '1',
      amount: '',
      init: false,
      pay_setting: ''
    }
  },
  created () {
    console.log(this.$route.query)
    document.title = '收银台'
    this.getPaySetting()
    this.amount = this.$route.query.amount
    if (this.$isWechat()) {
      this.wechatPay()
    } else {
      this.init = true
    }
  },
  methods: {
    getPaySetting () {
      paySetting({
        pay_client: 'H5'
      })
        .then((res) => {
          this.pay_setting = res
        })
        .catch((err) => {
          // loading.close()
          console.log(err)
        })
    },
    wechatPay () {
      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const code = this.$route.query.code
      pay({
        code: code,
        url_type: 'platform',
        is_frozen: '0',
        account: 'platform',
        pay_org_id: this.$route.query.org_id,
        receipt_org_id: this.$route.query.receipt_org_id,
        amount: this.$route.query.amount,
        url: this.getBackUrl(code),
        back_url: '',
        payment_type: this.$route.query.payment_type,
        pay_methods: 'WX',
        pay_channel: 'wx',
        remark: this.$route.query.remark ? this.$route.query.remark : '',
        agree_id: '',
        pay_platform: 'H5',
        wx_pay_type: 'js',
        open_id: this.$route.query.open_id ? this.$route.query.open_id : this.$route.query.openid ? this.$route.query.openid : ''
      })
        .then((res) => {
          localStorage.removeItem('isDisabledGet')
          localStorage.removeItem('payPhone')
          loading.close()
          this.onBridgeReady(res, code)
        })
        .catch((err) => {
          this.$toast(err.msg || '支付失败')
          loading.close()
        })
    },
    onBridgeReady (data, code) {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          appId: data.appId, // 公众号ID，由商户传入
          timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, // 随机串
          package: data.package,
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        res => {
          console.log('支付结果', res)
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // alert(this.getBackUrl(code))
            setTimeout(() => {
              // window.location.href = this.getBackUrl(code)
              console.log(this.getBackUrl(code))
              location.replace(this.getBackUrl(code))
            }, 1000)
          }
        })
    },
    // 获取静态链接跳
    getBackUrl (code) {
      var host = window.location.host
      let url = 'http://192.168.0.193:8080/#'
      let params = ''
      if (host === 't-static-h5.tevv.com.cn') {
        url = 'http://t-static-h5.tevv.com.cn/#' // 测试域名
      } else if (host === 'p-static-h5.tevv.com.cn') {
        url = 'https://p-static-h5.tevv.com.cn/#' // 正式域名
      } else if (host === 'r-static-h5.tevv.com.cn') {
        url = 'http://r-static-h5.tevv.com.cn/#' // 正式域名
      }
      if (this.$route.query.payType == 'confrimOrder') { // 购买商品
        params = `/pay/productPayResult?order_code=${code}`
      } else if (this.$route.query.payType == 'articleProduct') { // 活动商品
        const open_id = this.$route.query.open_id ? this.$route.query.open_id : this.$route.query.openid ? this.$route.query.openid : ''
        params = `/pay/result?u_id=${this.$route.query.uid}&mobile=${this.$route.query.phone}&article_code=${this.$route.query.article_code}&user_code=${this.$route.query.user_code}&record_code=${this.$route.query.record_code}&product_id=${this.$route.query.product_id}&order_code=${code}&appid=${this.$route.query.appid}&org_id=${this.$route.query.org_id}&commission=${this.$route.query.commission}&nickname=${this.$route.query.nickname}&headimgurl=${this.$route.query.headimgurl}&open_id=${open_id}&uid=${this.$route.query.uid}&access_token=${this.$route.query.access_token}`
      } else if (this.$route.query.payType == 'posterOrder') { // 购买商品
        params = `/pay/posterPayResult?order_code=${code}`
      }
      return url + params
    },
    onClickLeft () {
      this.$router.back()
    },
    // 获取app链接
    getPaySueesccUrl () {
      var host = window.location.host
      let url = ''
      if (host === 't-static-h5.tevv.com.cn') {
        url = 'http://t-nz-app.tevv.com.cn' // 测试域名
      } else if (host === 'p-static-h5.tevv.com.cn') {
        url = 'https://p-nz-app.tevv.com.cn' // 正式域名
      } else if (host === 'r-static-h5.tevv.com.cn') {
        url = 'http://r-nz-app.tevv.com.cn' // 正式域名
      }
      return url
    },
    pay () {
      const url = encodeURIComponent(this.getBackUrl(this.$route.query.code))
      const back_url = `${this.getPaySueesccUrl()}/fund_account/success_html?order_code=${this.$route.query.code}&url=${url}&payPlatform=h5`
      const pay_methods = this.type === '1' ? 'WX' : 'ZFB'
      payUnified({
        code: this.$route.query.code,
        url_type: 'platform',
        is_frozen: '0',
        account: 'platform',
        pay_org_id: this.$route.query.org_id,
        receipt_org_id: this.$route.query.receipt_org_id,
        amount: this.$route.query.amount,
        url: this.type === '1' ? encodeURIComponent(back_url) : back_url,
        back_url: '',
        payment_type: this.$route.query.payment_type,
        pay_methods: this.type === '1' ? 'WX' : 'ZFB',
        pay_channel: this.pay_setting[pay_methods].pay_channel_code,
        remark: '',
        agree_id: '',
        pay_platform: 'H5'
      })
        .then((res) => {
          localStorage.removeItem('isDisabledGet')
          localStorage.removeItem('payPhone')
          if (this.type === '1') { // 微信
            const url = `${this.getPaySueesccUrl()}/fund_account/h5pay?url=${encodeURIComponent(res.url)}`
            location.replace(url)
          } else {
            location.replace(res.url)
          }
        })
        .catch((err) => {
          this.$toast(err.msg || '支付失败')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #f3f4f6;

  .back-icon {
    height: 20px;
  }

  .title {
    font-weight: 400;
    font-size: 14px;
  }

  .content {
    padding: 30px;
    text-align: center;
    color: #fa675c;
    font-size: 22px;

    .content-title {
      padding-bottom: 14px;
      font-size: 14px;
      color: #202122;
    }
  }

  .card {
    background: #fff;
    border-radius: 4px;
    margin: 0 8px;
    padding: 15px 0 5px;
    padding-left: 15px;
    .card-title {
      padding-bottom: 10px;
      font-size: 15px;
    }

    .type-item {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 0;
      padding-right: 20px;
      padding-left: 5px;

      &:first-child {
        border-bottom: 1px solid #eee;
      }

      .type-left {
        display: flex;
        align-items: center;
        flex: 1;
      }
    }

    .type-icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    background: #fff;
    padding: 5px 8px;
    color: #fff;
    left: 0;
    right: 0;

    .footer-button {
      background: #00a0ac;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 15px;
      border-radius: 4px;
    }
  }
}
</style>
